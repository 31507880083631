<script>
export default {
  name: 'Platform',
  props: {
    value: {
      type: [String],
      required: true,
    },
  },
  computed: {
    formattedValue() {
      if (this.value === 'ios') return 'iOS';
      if (this.value === 'android') return 'Android';
      if (this.value === 'desktop') return 'Desktop';
      if (this.value === 'all') return 'All';
      return this.value;
    },
  },
};
</script>

<template lang="pug">
span {{ formattedValue }}
</template>
